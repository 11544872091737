import React, { ReactNode } from "react";
import styled from "styled-components";

import Section from "../../atoms/Section/Section";
import Wrapper from "../../atoms/Wrapper/Wrapper";
import Heading from "../../atoms/Heading/Heading";
import SubHeading from "../../atoms/SubHeading/SubHeading";
import responsive from "../../../../responsive";

// @ts-ignore
import contactimg from "../../../images/contact.jpg";

export interface ContactSectionProps {}

const ContactContainer = styled.div`
  line-height: 170%;
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (min-width: ${responsive.md}) {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
`;

const LeftText = styled.div`
  color: #fff;
  span {
    color: #82c341;
  }
`;

const ContactImage = styled.div`
  @media only screen and (min-width: ${responsive.md}) {
    background-image: url(${contactimg});
    background-repeat: no-repeat;
    background-position: center right;
    height: 300px;
  }
`;

const ContactSection: React.FC<ContactSectionProps> = (
  props: ContactSectionProps
) => {
  const {} = props;

  return (
    <>
      <a id="contactSection"></a>
      <Section color="#000000">
        <Wrapper>
          <ContactContainer>
            <ContactGrid>
              <div>
                <LeftText>
                  <Heading>
                    contact <span>us</span>
                  </Heading>
                  <br />
                  <br />
                  Contacting us is easy!
                  <br />
                  <br />
                  <SubHeading color="#82c341">
                    email our sales department
                  </SubHeading>
                  <br />
                  sales@frontward.com
                  <br />
                  <br />
                  <SubHeading color="#82c341">
                    email our support department
                  </SubHeading>
                  <br />
                  support@frontward.com
                  <br />
                  <br />
                  Note: Most technical issues can be fixed within 24 hours of
                  contact made.
                </LeftText>
              </div>
              <div>
                <ContactImage />
              </div>
            </ContactGrid>
          </ContactContainer>
        </Wrapper>
      </Section>
    </>
  );
};

export default ContactSection;
