import { createGlobalStyle } from "styled-components";
import { normalize } from "polished";

const GlobalStyles = createGlobalStyle`
    ${normalize}

    @import url('https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&family=Titillium+Web:wght@400;700&display=swap');

    *, html, body {
        font-family: 'Titillium Web', Arial, Tahoma, sans-serif;
    }
`;

export default GlobalStyles;
