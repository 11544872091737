import React, { ReactNode } from "react";
import styled from "styled-components";

import responsive from "../../../../responsive";

export interface HeadingProps {
  size?: string;
  color?: string;
  children?: ReactNode | string;
}

const Heading = styled.h1<HeadingProps>`
  margin: 0;
  font-size: ${(props) => (props.size ? props.size : "56px")};
  font-weight: normal;
  color: ${(props) => (props.color ? props.color : "#fff")};
  line-height: 100%;

  @media only screen and (min-width: ${responsive.md}) {
    margin: 0;
    font-size: ${(props) => (props.size ? props.size : "56px")};
    font-weight: normal;
    color: ${(props) => (props.color ? props.color : "#fff")};
  }
`;

export default Heading;
