import React, { ReactNode } from "react";
import styled from "styled-components";

import NavBar from "../../molecules/NavBar/NavBar";
import Link from "../../atoms/Link/Link";
import Wrapper from "../../atoms/Wrapper/Wrapper";
import responsive from "../../../../responsive";

// @ts-ignore
import logo from "../../../images/header-img.png";

export interface HeaderProps {}

const Heading = styled.div`
  background-color: #000000;
`;

const LogoContainer = styled.div`
  padding-top: 4%;
  padding-bottom: 2%;
  width: 100%;
  text-align: center;

  @media only screen and (min-width: ${responsive.md}) {
    padding-top: 4%;
    padding-bottom: 2%;
    width: 100%;
  }
`;

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const {} = props;

  return (
    <Heading>
      <Wrapper>
        <LogoContainer>
          <Link to="/">
            <img src={logo} />
          </Link>
        </LogoContainer>
        <NavBar />
      </Wrapper>
    </Heading>
  );
};

export default Header;
