import React, { ReactNode } from "react";
import styled from "styled-components";

import responsive from "../../../../responsive";

export interface CardProps {
  color?: string;
  children?: ReactNode | string;
}

const SCard = styled.div`
  margin-bottom: 2rem;
  width: 300px;
  height: 300px;
  border-radius: 10px;
  text-align: center;
  background: ${(props) => (props.color ? props.color : "#fff")};

  h1 {
    padding-top: 5rem;
  }

  @media only screen and (min-width: ${responsive.md}) {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    text-align: center;
    background: ${(props) => (props.color ? props.color : "#fff")};

    h1 {
      padding-top: 5rem;
    }
  }
`;

const Card: React.FC<CardProps> = (props: CardProps) => {
  const { color, children } = props;

  return <SCard color={color}>{children}</SCard>;
};

export default Card;
