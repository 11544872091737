import React, { ReactNode } from "react";
import styled from "styled-components";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {
  faCube,
  faCloud,
  faTv,
  faCommentAlt,
  faEnvelope,
  faInbox,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import responsive from "../../../../responsive";

// import Link from "../../atoms/Link/Link";

export interface NavBarProps {}

const NavContainer = styled.div`
  display: none;

  @media only screen and (min-width: ${responsive.md}) {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 2.5%;
    padding-left: 2rem;
    padding-right: 2rem;

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: #82c341;
        svg {
          color: #82c341;
        }
      }
    }
  }
`;

const IconContainer = styled.div`
  text-align: center;

  @media only screen and (min-width: ${responsive.md}) {
    color: #fff;
    display: inline-block;
    padding-right: 0.5rem;
  }
`;

const links = [
  {
    title: "Packages",
    href: "#heroSection",
    icon: faCube,
  },
  {
    title: "Cloud Hosting",
    href: "#cloudSection",
    icon: faCloud,
  },
  {
    title: "Multi-Platform",
    href: "#platformSection",
    icon: faTv,
  },
  {
    title: "Contact Us",
    href: "#contactSection",
    icon: faCommentAlt,
  },
];

const NavBar: React.FC<NavBarProps> = (props: NavBarProps) => {
  const {} = props;

  return (
    <NavContainer>
      {links.map((link) => (
        <div>
          <AnchorLink href={link.href}>
            <IconContainer>
              <FontAwesomeIcon icon={link.icon} />
            </IconContainer>
            {link.title}
          </AnchorLink>
        </div>
      ))}
      <div>
        <a href="http://emailhelp.frontward.com" target="_blank">
          <IconContainer>
            <FontAwesomeIcon icon={faEnvelope} />
          </IconContainer>
          Email Help?
        </a>
      </div>
      <div>
        <a href="http://email.frontward.com" target="_blank">
          <IconContainer>
            <FontAwesomeIcon icon={faInbox} />
          </IconContainer>
          Online Webmail
        </a>
      </div>
    </NavContainer>
  );
};

export default NavBar;
