import React, { ReactNode } from "react";
import styled from "styled-components";

import Section from "../../atoms/Section/Section";
import Heading from "../../atoms/Heading/Heading";
import Wrapper from "../../atoms/Wrapper/Wrapper";
import PackageCards from "../../molecules/PackageCards/PackageCards";

// @ts-ignore
import hero from "../../../images/bckPackages.jpg";

export interface HeroSectionProps {}

const Stripe = styled.div`
  background-color: rgba(000, 0, 0, 0.7);
  text-align: center;
  margin-bottom: 4.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.65rem;

  span {
    color: #82c341;
  }
`;

const HeroSection: React.FC<HeroSectionProps> = (props: HeroSectionProps) => {
  const {} = props;

  return (
    <>
      <a id="heroSection"></a>
      <Section background={hero}>
        <Stripe>
          <Heading size="45px">
            front<span>ward</span> packages
          </Heading>
        </Stripe>
        <Wrapper>
          <PackageCards />
        </Wrapper>
      </Section>
    </>
  );
};

export default HeroSection;
