import React, { ReactNode } from "react";
import styled from "styled-components";
import { Link as GLink } from "gatsby";

export interface LinkProps {
  to: string;
  children?: ReactNode | string;
}

const SLink = styled(GLink)`
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #82c341;
  }
`;

const Link: React.FC<LinkProps> = (props: LinkProps) => {
  const { to, children } = props;

  return <SLink to={to}>{children}</SLink>;
};

export default Link;
