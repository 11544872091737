import React, { ReactNode } from "react";
import styled from "styled-components";

import responsive from "../../../../responsive";

export interface SectionProps {
  background?: string;
  color?: string;
  children?: ReactNode | string;
}

const Sec = styled.div<SectionProps>`
  background-size: cover;
  background-position: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: ${(props) => (props.color ? props.color : "#000")};
  ${(props) =>
    props.background &&
    `
    background-image: url(${props.background});
  `}

  @media only screen and (min-width: ${responsive.md}) {
    background-size: cover;
    background-position: center;
    padding-top: 5.25%;
    padding-bottom: 5.25%;
    background-color: ${(props) => (props.color ? props.color : "#000")};
    ${(props) =>
      props.background &&
      `
      background-image: url(${props.background});
    `}
  }
`;

const Section: React.FC<SectionProps> = (props: SectionProps) => {
  const { background, color, children } = props;

  return (
    <Sec background={background} color={color}>
      {children}
    </Sec>
  );
};

export default Section;
