import React, { ReactNode } from "react";
import styled from "styled-components";

export interface SubHeadingProps {
  color?: string;
  children?: ReactNode | string;
}

const SubHeading = styled.h3<SubHeadingProps>`
  font-size: 32px;
  margin: 0;
  font-weight: normal;
  color: ${(props) => (props.color ? props.color : "#000")};
`;

export default SubHeading;
