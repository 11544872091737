import React from "react";
import styled from "styled-components";

import Card from "../../atoms/Card/Card";
import Heading from "../../atoms/Heading/Heading";
import responsive from "../../../../responsive";

const CardGrid = styled.div`
  @media only screen and (min-width: ${responsive.md}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
`;

const PackageCard = styled(Card)`
  margin: auto;
  margin-bottom: 2rem;
`;

const CardContent = styled.div`
  color: ${(props) => (props.color ? props.color : "#fff")};
`;

const CardHeading = styled(Heading)`
  font-size: 40px;
  font-family: "Pathway Gothic One", sans-serif;
  font-weight: 800;
`;

const CardColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
`;

const PackageCards: React.FC<{}> = () => {
  return (
    <CardGrid>
      <CardColumn>
        <PackageCard color="#dceab7">
          <CardHeading color="#54843c">STANDARD</CardHeading>
          <CardContent color="#54843c">
            1000 MB Disk Space <br />
            10 GB Data Transfer
            <br />
            20 Email Accounts
            <br />
            FTP Access
            <br />
            and much more
          </CardContent>
        </PackageCard>
      </CardColumn>
      <CardColumn>
        <PackageCard color="#aee0eb">
          <CardHeading color="#2d7079">ADVANCED</CardHeading>
          <CardContent color="#2d7079">
            5000 MB Disk Space
            <br />
            20 GB Data Transfer
            <br />
            100 Email Accounts
            <br />
            MySQL Databases
            <br />
            and much more
          </CardContent>
        </PackageCard>
      </CardColumn>
      <CardColumn>
        <PackageCard color="#faeed4">
          <CardHeading color="#705f26">CUSTOM</CardHeading>
          <CardContent color="#705f26">
            MB Disk Space
            <br />
            GB Data Transfer
            <br />
            Email Accounts
            <br />
            MySQL Databases
            <br />
            Custom to You
          </CardContent>
        </PackageCard>
      </CardColumn>
    </CardGrid>
  );
};

export default PackageCards;
