import React, { ReactNode } from "react";
import styled from "styled-components";

import Wrapper from "../../atoms/Wrapper/Wrapper";
import responsive from "../../../../responsive";

export interface FooterProps {}

const Footing = styled.div`
  background-color: #000000;
`;

const FooterText = styled.div`
  text-align: center;
  color: #fff;
  padding-top: 0;
  padding-bottom: 80px;
  line-height: 170%;

  a {
    color: #82c341;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const TextBreak = styled.span`
  display: block;
  @media only screen and (min-width: ${responsive.md}) {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const {} = props;

  return (
    <Footing>
      <Wrapper>
        <FooterText>
          Contact us today to start saving money on monthly web hosting costs!
          <TextBreak>Phone: 567-318-4404</TextBreak>
          <TextBreak>Email: sales@frontward.com</TextBreak>
          <br />
          <TextBreak>Frontward Web Hosting</TextBreak>
          <a href="http://frontward.com">www.frontward.com</a>
        </FooterText>
      </Wrapper>
    </Footing>
  );
};

export default Footer;
