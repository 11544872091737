import React, { ReactNode } from "react";
import styled from "styled-components";

import Section from "../../atoms/Section/Section";
import Wrapper from "../../atoms/Wrapper/Wrapper";
import Heading from "../../atoms/Heading/Heading";
import SubHeading from "../../atoms/SubHeading/SubHeading";
import responsive from "../../../../responsive";

// @ts-ignore
import cloudimg from "../../../images/cloud-hosting-icon.jpg";

export interface CloudSectionProps {}

const ContentContainer = styled.div`
  line-height: 155%;
`;

const CloudGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (min-width: ${responsive.md}) {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
`;

const LeftText = styled.div`
  color: #fff;
  span {
    color: #82c341;
  }
`;

const CloudImage = styled.div`
  @media only screen and (min-width: ${responsive.md}) {
    background-image: url(${cloudimg});
    background-repeat: no-repeat;
    background-position: center right;
    height: 300px;
  }
`;

const CloudSection: React.FC<CloudSectionProps> = (
  props: CloudSectionProps
) => {
  const {} = props;

  return (
    <>
      <a id="cloudSection"></a>
      <Section color="#000000">
        <Wrapper>
          <ContentContainer>
            <CloudGrid>
              <div>
                <LeftText>
                  <Heading>
                    cloud <span>hosting</span>
                  </Heading>
                  <br />
                  <br />
                  Frontward is not your typical web host. We don’t host your
                  site on a single server, possibly shared with other web sites,
                  representing a single point of failure. Instead, we use{" "}
                  <strong>cloud hosting.</strong> <br />
                  <br />
                  Cloud hosting with Frontward is fast, easy, and reliable. Our
                  system is fully managed and we offer great support. Best of
                  all, you don’t have to worry about your website going down..
                  <br />
                  <br />
                  <SubHeading color="#82c341">reliable</SubHeading>
                  <br />
                  Through the use of multiple redundant sites, your site will
                  have essentially 0% downtime. The architecture powering our
                  hosting uses groups of storage devices to reliably serve every
                  web page, image, and email.
                  <br />
                  <br />
                  <SubHeading color="#82c341">scalable</SubHeading>
                  <br />
                  You can use resources when you need them. Your site won’t go
                  down if you get a heavy traffic volume. You don’t even have to
                  do anything -- we’ll take care of providing your site with
                  more resources if and when it needs it.
                  <br />
                  <br />
                  <SubHeading color="#82c341">secure</SubHeading>
                  <br />
                  We all know sometimes computers crash. With the cloud, if one
                  server crashes, your site won’t go down due to our multiple
                  redundant sites. Of course, we still back up your data every
                  night and store it off-site in case of things like major
                  natural disasters.
                </LeftText>
              </div>
              <div>
                <CloudImage />
              </div>
            </CloudGrid>
          </ContentContainer>
        </Wrapper>
      </Section>
    </>
  );
};

export default CloudSection;
