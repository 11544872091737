import React, { ReactNode } from "react";
import styled from "styled-components";

import Section from "../../atoms/Section/Section";
import Wrapper from "../../atoms/Wrapper/Wrapper";
import Heading from "../../atoms/Heading/Heading";
import SubHeading from "../../atoms/SubHeading/SubHeading";
import responsive from "../../../../responsive";

// @ts-ignore
import platimg from "../../../images/platform.jpg";

export interface PlatformSectionProps {}

const Container = styled.div`
  line-height: 200%;
`;

const PlatformGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (min-width: ${responsive.md}) {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
`;

const RightText = styled.div`
  text-align: left;

  color: #838383;
  span {
    color: #259ca6;
  }

  @media only screen and (min-width: ${responsive.md}) {
    text-align: right;

    color: #838383;
    span {
      color: #259ca6;
    }
  }
`;

const PlatformImage = styled.div`
  @media only screen and (min-width: ${responsive.md}) {
    background-image: url(${platimg});
    background-repeat: no-repeat;
    background-position: center left;
    height: 300px;
  }
`;

const PlatformTable = styled.table`
  text-align: center;
  border-spacing: 0px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  line-height: 100%;

  th {
    padding: 10px;
    background-color: #259ca6;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    color: #fff;
  }

  td {
    border-left: 1px solid #ccc;
    padding: 6px;
  }
`;

const PlatformSection: React.FC<PlatformSectionProps> = (
  props: PlatformSectionProps
) => {
  const {} = props;

  return (
    <>
      <a id="platformSection"></a>
      <Section color="#fff">
        <Wrapper>
          <Container>
            <PlatformGrid>
              <div>
                <PlatformImage />
              </div>
              <div>
                <RightText>
                  <Heading color="#838383">
                    multi-<span>platform</span>
                  </Heading>
                  <br />
                  Both Windows and Linux are available to host. We use the
                  latest technologies in both platforms.
                  <br />
                  <br />
                  <SubHeading color="#259ca6">plans</SubHeading>
                  <br />
                  We offer two plans at competitive costs. We have rates at
                  month-to-month, or pre-payment for 3 months or 1 year, with
                  discounts offered for the pre-payment plans.
                  <br />
                  <br />
                  <PlatformTable>
                    <tbody>
                      <tr>
                        <th>Standard</th>
                        <th>Advanced</th>
                        <th>Custom</th>
                      </tr>
                      <tr>
                        <td>1000 MB Disk Space</td>
                        <td>5000 MB Disk Space</td>
                        <td rowSpan={4}>Tailor made for you</td>
                      </tr>
                      <tr>
                        <td>10 GB Data Transfer</td>
                        <td>20 GB Data Transfer</td>
                      </tr>
                      <tr>
                        <td>20 Email Accounts</td>
                        <td>100 Email Accounts</td>
                      </tr>
                      <tr>
                        <td>5 MySQL Databases</td>
                        <td>5 MySQL Databases</td>
                      </tr>
                    </tbody>
                  </PlatformTable>
                  <br />
                  Microsoft SQL Databases are also available for an additional
                  cost of $6 per month.
                  <br />
                  <br />
                  <SubHeading color="#259ca6">features</SubHeading>
                  <br />
                  We have the following features on all of our plans.
                  <br />
                  <br />
                  <PlatformTable>
                    <tbody>
                      <tr>
                        <th>Application Frameworks</th>
                        <th>
                          PHP 5, Perl, Python, MySQL 5, .Net 2.0, 3+3.5, ASP, MS
                          SQL 2008
                        </th>
                      </tr>
                      <tr>
                        <th>Access</th>
                        <td>
                          File Transfer Protocol (FTP), Secure File Transfer
                          Protocol (SFTP)
                        </td>
                      </tr>
                      <tr>
                        <th>GUI</th>
                        <td>
                          Custom control panel to fully take advantage of our
                          Cloud.
                        </td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>
                          POP/IMAP, and Webmail with shared calendars, contacts
                          and tasks
                        </td>
                      </tr>
                      <tr>
                        <th>Windows Components</th>
                        <td>
                          Common components are available, such as AspUpload,
                          AspJpeg, CDONTS and CDOSYS
                        </td>
                      </tr>
                      <tr>
                        <th>DNS Management</th>
                        <td>Management console available</td>
                      </tr>
                    </tbody>
                  </PlatformTable>
                </RightText>
              </div>
            </PlatformGrid>
          </Container>
        </Wrapper>
      </Section>
    </>
  );
};

export default PlatformSection;
