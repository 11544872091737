import React, { ReactNode } from "react";

import Header from "../../organisms/Header/Header";
import Footer from "../../organisms/Footer/Footer";
import ContactSection from "../../organisms/ContactSection/ContactSection";
import HeroSection from "../../organisms/HeroSection/HeroSection";
import CloudSection from "../../organisms/CloudSection/CloudSection";
import PlatformSection from "../../organisms/PlatformSection/PlatformSection";
import GlobalStyles from "../../atoms/GlobalStyles/GlobalStyles";

export interface MainLayoutProps {}

const MainLayout: React.FC<MainLayoutProps> = (props: MainLayoutProps) => {
  const {} = props;

  return (
    <>
      <GlobalStyles />
      <Header />
      <HeroSection />
      <CloudSection />
      <PlatformSection />
      <ContactSection />
      <Footer />
    </>
  );
};

export default MainLayout;
